* {
  font-family: 'Inter', sans-serif;
}

::selection {
  background:rgba(0,0,0,0.4);
  color:white;
}

::-webkit-scrollbar {
  width:5px;
}

::-webkit-scrollbar-thumb {
  background:#C1C1C1; 
}

::-webkit-scrollbar-track {
  background:#F1F1F1;
}

body {
  background:rgb(255, 255, 255);
  
  /* background:rgb(242, 245, 248); */
}

.dialog-title-text {
  display:flex;
  align-items:center;
}

.invoice-items-header {
  box-sizing:border-box;
  padding:20px;
}

.invoice-items-header h5 {
  margin:0;
  font-weight:400;
  color:rgb(156, 156, 156);
}

.invoice-items-header h5.offset-left-20 {
  margin-left:-20px;
}

.invoice-item-wrapper {
  margin-bottom:15px;
}

.invoice-item {
  box-sizing:border-box;
  padding:20px;
  background:white;
  box-shadow:0 0 10px rgba(0,0,0,0.0);
  border:1px solid #EEEEEE;
  /* border:1px solid rgba(0, 0, 0, 0.18); */
  border-radius:0;
}

.invoice-item * {
  display:flex;
  align-items:center;
}

.invoice-item .status {
  border-radius:0;
  display:table;
  box-sizing: border-box;
  padding:3px 7px;
}

.app-container {
  display:flex;
  margin-top:3vw;
  margin-bottom:3vw;
}

a.active-link {
  color: #0053FF;
  background: #F5F8FF;
}